import React from "react"
const Footer = () => {
  return <footer className="footer">
    <div>
      <h4>woundlaw</h4>
      <h4>copyright &copy; 
        <span>woundlaw</span> all rights reserved
      </h4>
    </div>
  </footer>
}

export default Footer
