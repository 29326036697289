import logo from "../assets/WoundLawLogo.png"
import React from 'react'

function Logo() {
    return (
        <img className="logo" src={logo} alt="logo"/>
    )
}

export default Logo
